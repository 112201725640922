<template>
    <div>
        <Pane />
        <a-card class="container">
            <a-row style="margin-bottom: 10px;">
                <a-col :span="24">
                <a-form-model layout="inline">
                    <a-form-model-item>
                        <a-input placeholder="项目名称" v-model="form.name" style="width: 150px" />
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-select placeholder="中标情况" v-model="form.status" style="width: 120px">
                        <a-select-option
                            v-for="item in status"
                            :key="item.value"
                            :value="item.value"
                        >{{ item.name }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item>
                    <a-select
                        placeholder="生产部门"
                        v-model="form.deptId"
                        show-search
                        style="width: 160px"
                        :filter-option="$selectFilterOption"
                    >
                        <a-select-option
                        v-for="item in organizationList"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.uniqueName }}</a-select-option
                        >
                    </a-select>
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-range-picker v-model="form.date" style="width: 200px" />
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-space>
                            <a-button @click="query" type="primary">查询</a-button>
                            <a-button @click="reset">重置</a-button>
                        </a-space>
                    </a-form-model-item>
                    <a-button v-if="$getPermission($route.path + '/edit')" @click.prevent="download()" style="float: right;margin-top: 4px;margin-left: 10px;">导出台账</a-button>
                    <a-button v-if="$getPermission($route.path + '/add')" @click.prevent="$router.push($route.path + '/add')" type="primary" style="float: right;margin-top: 4px">新增</a-button>
                </a-form-model>
                </a-col>  
            </a-row>
            <a-table bordered :dataSource="tableList" :loading="loading" @change="onChange" :pagination="{
                total,
                current,
                pageSize,
                showTotal: (total) => `共 ${total} 条记录`,
            }" rowKey="id">
                    <a-table-column title="项目名称" data-index="name" width="550px"></a-table-column>
                    <a-table-column title="开标日期" data-index="date" align="center"></a-table-column>
                    <a-table-column title="中标情况" align="center">
                        <template slot-scope="text">
                            <DataDictFinder dictType="ds_bid" :dictValue="text?.status" />
                        </template>
                    </a-table-column>
                    <a-table-column title="中标金额(万元)" data-index="amount" align="center"></a-table-column>
                    <a-table-column title="未中标原因" data-index="remark" align="center"></a-table-column>
                    <a-table-column title="生产部门" data-index="deptName" align="center"></a-table-column>
                    <a-table-column title="操作" align="center" width="120px">
                        <template slot-scope="text">
                            <a-space>
                                <a
                                    href="#"
                                    v-if="$getPermission($route.path + '/edit')"
                                    @click.prevent="$router.push($route.path + '/edit?id=' + text.id)"
                                    >编辑</a
                                >
                                <a href="#" class="danger" v-if="$getPermission($route.path + '/edit')" @click.prevent="deleteText(text.id)"
                                    >删除</a
                                >
                            </a-space>
                        </template>
                        </a-table-column>
            </a-table>
        </a-card>
    </div>
</template>
<script>
import request from "@/api/request";
import { mapGetters } from "vuex";
import organization from "@/mixins/organization";
import download from '@/api/download'
import { saveAs } from "file-saver";
function fetchList(params) {
    return request({
        url: "project-service/dsBid/list",
        params: params
    });
}

function fetchNum(year) {
    return request({
        url: "user-service/talentMap/last/" + year,
    });
}

function remove(id) {
  return request({
    url: "project-service/dsBid/del/" + id,
  });
}

function exportFile(data) {
    return download({
        url: "/project-service/dsBid/exportExc",
        method: 'post',
        data: data,
    });
}
export default {
    mixins: [organization],
    props: {
        year: {
            type: Number,
            default: 0
        }
    },
    watch:{
        year(newValue){
            this.now = newValue
            fetchNum(this.now).then(res => {
                this.total = res && res.staffTotal?res.staffTotal:0
            })
        }
    },
    computed: {
        ...mapGetters("setting", ["findDataDict"]),
        ...mapGetters("auth", ["isHr"]),
        status() {
            return this.findDataDict("ds_bid");
        },
    },
    data() {
        return {
            form: {
                deptId: ''
            },
            now: 0,
            current: 1,
            pageSize: 10,
            total: 0,
            loading: false,
            total: 0,
            dialogShow: false,
            tableList: []
        };
    },
    mounted() {
        const { query } = this.$route;
        const { id } = query || {};
        this.form.deptId = id
        this.getList()
    },
    methods: {
        download() {
            const { date } = this.form;
            let dateStart, dateEnd;
            if (Array.isArray(date) && date.length === 2) {
                dateStart = date[0].format("YYYY-MM-DD");
                dateEnd = date[1].format("YYYY-MM-DD");
            }
            exportFile({name:this.form.name, status:this.form.status, deptId: this.form.deptId, dateStart:dateStart, dateEnd:dateEnd,  pageNum:this.current,pageSize:this.pageSize})
            .then(blob => {
                saveAs(blob, "投标总览.xlsx");
            })
            .catch(err => {
            console.log("err", err);
            })
            .finally(() => {
            hide();
            });
        },
        deleteText(id) {
            const that = this;
            this.$confirm({
                title: "确认要删除吗？",
                onOk() {
                    remove(id).then(()=>{
                        that.getList();
                    })
                },
            });
        },
        query() {
            this.current = 1;
            this.getList();
        },
        reset() {
            this.form = {};
            this.getList();
        },
        getList(){
            this.loading = true
            const { date } = this.form;
            let dateStart, dateEnd;
            if (Array.isArray(date) && date.length === 2) {
                dateStart = date[0].format("YYYY-MM-DD");
                dateEnd = date[1].format("YYYY-MM-DD");
            }
            fetchList({name:this.form.name, status:this.form.status, deptId: this.form.deptId, dateStart:dateStart, dateEnd:dateEnd,  pageNum:this.current,pageSize:this.pageSize}).then(res => {
                this.tableList = res.list
                this.total = res.totalSize
            }).finally(() => {
                this.loading = false
            })
        },
        onChange(pagination) {
            this.current = pagination.current;
            this.pageSize = pagination.pageSize;
            this.getList()
        },
        showDialog() {
            this.getList();
        }
    }
}
</script>
<style>
.selectNoBorder .ant-select-selection{
  border: none;background: none;color: rgba(0, 0, 0, 0.65);
}
.selectNoBorder .ant-select-arrow{
  display: none;
}
.selectNoBorder .ant-select-selection__rendered{
  margin: 0;
}
</style>